
import { Component, Vue } from "vue-property-decorator";
import TopAppBar from "@/components/base/TopAppBar.vue";

declare global {
  export const VK: any;
}

@Component({
  components: {
    TopAppBar,
  },
  metaInfo: {
    title: 'Отзывы',
  },
})
export default class Reviews extends Vue {
  get pageHeight() {
    return (
      this.$vuetify.breakpoint.height - this.$vuetify.application.insetFooter
    );
  }

  mounted() {
    VK.Widgets.Comments("vk_comments", { limit: 100, attach: "*" });
  }
}
